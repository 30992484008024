import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import card_html1 from './card_html1.js';
import card_html2 from './card_html2.js';
import card_html3 from './card_html3.js';
//TODO: actually make the edit field not gpt-based but directly into the html. Need a nice way to make it editable tho. Mabye literally just a window that displays the HTML?

function App() {
  const iframeRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [StatblockText, setStatblockText] = useState('');
  const [card, setCard] = useState(card_html1 + card_html2 + card_html3);
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (iframeRef.current) {
      const doc = iframeRef.current.contentWindow.document;
      doc.open();
      doc.write(card);
      doc.close();

      if (doc.body) {
        doc.body.contentEditable = true;
        doc.designMode = "on";

        doc.body.addEventListener('input', () => {
          setCard(doc.documentElement.outerHTML);
        });
      }
    }
  }, [card]);

  const handleSave = () => {
    const html = iframeRef.current.contentWindow.document.documentElement.outerHTML;
    const blob = new Blob([html], { type: 'text/html;charset=utf-8' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'output.html';
    link.click();
  };

  const handleStatblockTextChange = (e) => {
    setStatblockText(e.target.value);
  };

  async function handleSubmit() {
    setLoading(true);
    try {
      console.log("Making the first GENERATE call...");
      const response1 = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            { role: 'system', content: 'You are a an expert in designing 5e dnd statblocks that are balanced for the provided information. Return only a statblock according to the given information.' },
            { role: 'user', content: inputValue },
          ],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        }
      );

      const output1 = response1.data.choices[0].message.content;
      console.log(output1);
      setStatblockText(output1);

      console.log("Making the second GENERATE call...");
      const response2 = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            { role: 'system', content: `This is a template: ${card_html2}.` },
            { role: 'user', content: `Now return ONLY the template but updated with the following information: `+ output1 },
          ],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        }
      );

      const output2 = response2.data.choices[0].message.content;
      const extractedContent = output2.match(/(<stat-block>[\s\S]*?<\/stat-block>)/)[1];
      console.log(extractedContent);

      setCard(card_html1 + extractedContent + card_html3);

    } catch (error) {
      console.error('Error:', error);
    }
    setLoading(false);
  }

  async function handleUpdate() {
    setLoading(true);
    try {
      console.log("Making the first UPDATE call...");
      const response1 = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            { role: 'system', content: `You are a an expert in updating 5e dnd statblocks that are balanced for the provided information. This is the current statblock: ${StatblockText}. Update this statblokc accoring to the following: ` },
            { role: 'user', content: inputValue },
          ],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        }
      );

      const output1 = response1.data.choices[0].message.content;
      console.log(output1);
      setStatblockText(output1);

      console.log("Making the second UPDATE call...");
      const response2 = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            { role: 'system', content: `This is a template: ${card_html2}.` },
            { role: 'user', content: `Now return ONLY the template but updated with the following information: `+ output1 },
          ],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        }
      );

      const output2 = response2.data.choices[0].message.content;
      const extractedContent = output2.match(/(<stat-block>[\s\S]*?<\/stat-block>)/)[1];
      console.log(extractedContent);

      setCard(card_html1 + extractedContent + card_html3);

    } catch (error) {
      console.error('Error:', error);
    }
    setLoading(false);
  }

  async function handleSaveEdit() {
    setLoading(true);
    console.log(StatblockText);
    try {
      console.log("Making the first EDIT call...");
      const response2 = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            { role: 'system', content: `This is a template: ${card_html2}.` },
            { role: 'user', content: `Now return ONLY the template but updated with the following Statblock: `+ StatblockText +  `If the text does not include all needed information, remove the missing attributes from the template. Make sure all numbers are still correct and are the same as on the statblock provided`},
          ],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        }
      );

      const output2 = response2.data.choices[0].message.content;
      const extractedContent = output2.match(/(<stat-block>[\s\S]*?<\/stat-block>)/)[1];
      console.log(extractedContent);

      setCard(card_html1 + extractedContent + card_html3);

    } catch (error) {
      console.error('Error:', error);
    }
    setLoading(false);
  }

  return (
    <div style={{ height: '100vh', overflow: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around' }}>
      <div style={{ display: 'flex', gap: '10px' }}>
        <input
          type="text"
          placeholder="Enter text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          style={{ width: '300px', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
        />
        <button onClick={handleSubmit} disabled={loading} style={{ padding: '10px 20px', borderRadius: '5px', border: 'none', backgroundColor: '#007BFF', color: '#fff' }}>
          {loading ? 'Loading...' : 'Generate'}
        </button>
        <button onClick={handleUpdate} disabled={loading} style={{ padding: '10px 20px', borderRadius: '5px', border: 'none', backgroundColor: '#007BFF', color: '#fff' }}>
          {loading ? 'Loading...' : 'Update'}
        </button>
      </div>
      {loading && 
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px' }}>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{margin: "auto", background: "transparent", display: "block", shapeRendering: "auto"}} width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <circle cx="50" cy="50" fill="none" stroke="#3498db" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(275.845 50 50)">
              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
            </circle>
          </svg>
        </div>
      }
      <div style={{ display: 'flex', gap: '10px' }}>
        <iframe title="Card" ref={iframeRef} style={{ width: '400px', border: 'none', minHeight: '600px'}} frameBorder="0" />
        {isEditMode && <textarea value={StatblockText} style={{ width: '400px', height: 'auto', minHeight: '300px' }} onChange={handleStatblockTextChange} />}
      </div>
      <div style={{ display: 'flex', gap: '10px' }}>
        <button onClick={handleSave} style={{ padding: '10px 20px', borderRadius: '5px', border: 'none', backgroundColor: '#007BFF', color: '#fff' }}>
            Save HTML
        </button>
        <button onClick={() => setIsEditMode(!isEditMode)} style={{ padding: '10px 20px', borderRadius: '5px', border: 'none', backgroundColor: '#007BFF', color: '#fff' }}>
            {isEditMode ? 'Close Edit' : 'Edit'}
        </button>
        {isEditMode && <button onClick={handleSaveEdit} style={{ padding: '10px 20px', borderRadius: '5px', border: 'none', backgroundColor: '#007BFF', color: '#fff' }}>Save Edit</button>}
      </div>
    </div>
  );
}

export default App;
